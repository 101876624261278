/*!
#businessの独自設定
---------------------------- */
#business {
  margin-top: 0;

  // ------------------------------------------section__lv1

  .section__lv1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 58px;
    padding-bottom: 58px;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
    @include media(tb) {
      display: block;
      padding-top: 40px;
      padding-bottom: 8px;
    }  
    &-r {
      padding-left: 30px;
      padding-right: 30px;
      @include media(tb) {
        padding: 0;
        margin-top: 30px;
      }  
    }
  }
  .title {
    text-align: center;
    color: $color-main;
    line-height: 1.7;
    @include media(tb) {
    }  
    &-main {
      font-size: 35px;
      display: inline-block;
      text-align: left;
      @include media(tb) {
        font-size: 24px;
      }  
    }
    &-sub {
      font-size: 24px;
      padding-bottom: 22px;
      @include media(tb) {
        font-size: 16px;
      }  
    }
  }
  // ------------------------------------------section__lv1-end


  .content_text {
    text-align: center;
    margin-top: 14px;
    @include media(tb) {
    }
  }

  .lead__text {
    font-size: 16px;
    display: inline-block;
    text-align: center;
    line-height: 1.875;
    @include media(tb) {
      font-size: $fontsize-base;
      text-align: left;
      line-height: 1.785;
    }
  }

  .section_lead__text {
    font-size: 18px;
    display: inline-block;
    text-align: center;
    line-height: 1.875;
    @include media(tb) {
      font-size: $fontsize-base;
      text-align: left;
      line-height: 1.785;
    }
  }

  .img {

    &_business1-1 {
      max-width: 390px;
      width: 100%;
      @include media(tb) {
        padding-left: 12px;
        padding-right: 12px;
        margin: 0 auto;
      }
    }
    
    &_business1-2 {
      width: 242px;
      margin: 48px auto 0;
      @include media(tb) {
        margin-top: 8px;
        width: 132px;
      }
    }
    &_section__lv2 {
      width: 50%;
      @include media(tb) {
        width: 100%;
      }
      .img {
        border-radius: 20px;
      }
    }
    &_business2-5 {
      display: block;
      width: 71px;
      margin-left: auto;
      margin-right: 0;
      z-index: 1;
      margin-top: -10px;
      @include media(tb) {
        width: 53px;
        margin-top: 0;
      }
    }

    &_section__lv3 {
      width: 390px;
      @include media(tb) {
        width: 100%;
        margin-top: 32px;
      }
      .img {
        border-radius: 20px;
      }
    }
    &_section__lv4 {
      margin-top: 60px;
      width: 100%;
      @include media(tb) {
        margin-top: 40px;
      }
      .img {
        border-radius: 20px;
      }
    }
    &_business5-1 {
      width: 168px;
      flex-shrink: 0;
      @include media(tb) {
        width: 90px;
      }
    }

    &_section__lv5 {
      width: 224px;
      flex-shrink: 0;
      &.-odd {
        margin-left: 58px;
        @include media(tb) {
          margin: 40px auto 0;
        }  
      }
      &.-even {
        margin-right: 58px;
        @include media(tb) {
          margin: 40px auto 0;
        }  
      }

      .img {
        border-radius: 20px;
      }
    }
    &_business5-5 {
      width: 83px;
      flex-shrink: 0;
      @include media(tb) {
        width: 66px;
        margin: 0 auto;
      }  
    }
    &_business5-9 {
      width: 138px;
      flex-shrink: 0;
      @include media(tb) {
        width: 82px;
        margin: 0 auto;
      }
    }

    &_flow {
      margin-top: 60px;
      .img {
        border-radius: 50px;
      }
    }

    &_section__lv7 {
      width: 186px;
      margin: 0 auto;
    }
  }

  .shadow {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, .3));
  }



  .label {
    margin-right: 11.5px;
    line-height: 1;
    background-color: $color-main;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 4px;
    @include media(tb) {
      margin-bottom: 12px - margin((29/20),20);
      margin-right: 0;
    }
  }
//使いまわし
  .red {
    color: $color-main;
  }
  .fw_b {
    font-weight: 600;
  }
  .kakko {
    margin-left: -0.5em;
  }
  .pt {
    padding-top: 160px;
    @include media(tb) {
      padding-top: 80px;
    }
  }
//title
  .border_kado {
    &.-title {
      max-width: 430px;
    }
    margin: 0 7px;
    border-top: $border-waku;
    width: calc(100% - 14px);
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-bottom: $border-waku;
      border-right: $border-waku;
      border-bottom-right-radius: 100%;
      position: absolute;
      left: -7px;
      top: 0;
    }
    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-bottom: $border-waku;
      border-left: $border-waku;
      border-bottom-left-radius: 100%;
      position: absolute;
      right: -8px;
      top: 0;
    }
  }
  .border_kado_m {
    &.-title {
      max-width: 430px;
      @include media(pc) {
        text-align: center;
      }
    }
    margin: 0 7px;
    padding: 10px 10px 10px 14px;
    position: relative;
    &::before {
      content: "";
      display: block;
      height: calc(100% - 14px);
      border-left: $border-waku;
      position: absolute;
      left: -7px;
      top: 8px;
    }
    &::after {
      content: "";
      display: block;
      height: calc(100% - 14px);
      border-right: $border-waku;
      position: absolute;
      right: -8px;
      top: 8px;
    }
  }
  .border_kado_b {
    &.-title {
      max-width: 430px;
      margin-bottom: 23px;
      @include media(pc) {
        margin-bottom: 30px;
      }
    }
    margin: 0 7px;
    border-bottom: $border-waku;
    width: calc(100% - 14px);
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-top: $border-waku;
      border-right: $border-waku;
      border-top-right-radius: 100%;
      position: absolute;
      left: -7px;
      top: -7px;
    }
    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-top: $border-waku;
      border-left: $border-waku;
      border-top-left-radius: 100%;
      position: absolute;
      right: -8px;
      top: -7px;
    }
  }

  // ------------------------------------------section__lv2

  .section__lv2 {
    &_content {
      padding-top: 32px;
      padding-bottom: 60px;
      @include media(tb) {
        padding: 0;
      }
      .group {
        display: flex;
        margin-top: 44px;
        @include media(tb) {
          display: block;
          margin-top: 64px;
        }  
        &.-even {
          flex-direction: row-reverse;
        }
        .txt_box {
          width: 50%;
          @include media(tb) {
            width: 100%;
            margin-top: 16px;
          }  

          &.-odd {
            padding-left: 38px;
            @include media(tb) {
              padding-left: 0;
            }    
          }
          &.-even {
            padding-right: 38px;
            @include media(tb) {
              padding-right: 0;
            }    
          }
          .dl {
            width: 100%;
            height: 100%;
            border: $border-waku; 
            border-radius: 20px;
            background-color: #eff7f5;
            padding-left: 46px;
            padding-right: 46px;
            padding-top: 48px;
            padding-bottom: 30px;
            @include media(tb) {
              padding: 40px 32px;
            }
            &.-last {
              padding-bottom: 10px;
            }
          }
          .dt {
            color: $color-main;
            font-size: 22px;
            text-align: center;
            @include media(tb) {
              font-size: 18px;
              line-height: 1.4;
            }
            .span {
              display: inline-block;
              text-align: left;
            }
          }
          .dd {
            font-size: 16px;
            line-height: 2.2;
            padding-top: 28px;
            text-align: justify;
            @include media(tb) {
              font-size: 14px;
              line-height: 2.1;
            }    
          }
        }
  
      }
    }
  }
  // ------------------------------------------section__lv2-end


  // ------------------------------------------section__lv3

  .section__lv3 {
    &_content {
      margin-top: 100px;
      padding-bottom: 18px;
      @include media(tb) {
        margin-top: 0;
      }    
      .item{
      background-color: white;
      border: $border-waku;
      border-radius: 20px;
      padding: 36px 48px;
      margin-top: 60px;
      @include media(tb) {
        margin-top: 40px;
        padding: 32px 30px;
      }    
        .heading {
          font-size: 24px;
          color: $color-main;
          font-weight: normal;
          text-align: center;
          @include media(tb) {
            font-size: 18px;
          }        
        }
      }
    }
    .dl {
      margin-top: 44px;
      display: grid;
      width: 100%;
      height: 100%;
      grid-template:
      'dd_img ... dt' 24%
      'dd_img ... dd_txt'
      / auto 54px auto;
      @include media(tb) {
        display: block;
        margin-top: 32px;
      }
      .dt {
        grid-area: dt;
        font-size: 18px;
        margin-top: 30px;
        text-align: center;
        @include media(tb) {
          font-size: 16px;
        }
  
        .span {
          font-size: 33px;
          padding-left: 10px;
          display: inline-block;
          @include media(tb) {
            font-size: 24px;
          }
  
        }
      }
      .dd_img {
        grid-area: dd_img;
      }
      .dd_txt {
        text-align: justify;
        grid-area: dd_txt;
        font-size: 18px;
        line-height: 2.2;
        @include media(tb) {
          margin-top: 24px;
          font-size: 16px;
        }
      }
    }
  }
  // ------------------------------------------section__lv3-end

  // ------------------------------------------section__lv5
    .section__lv5 {

      &_content_text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 14px;
        @include media(tb) {
        }
      }
    
      &_lead__text {
        padding-left: 38px;
        font-size: 18px;
        display: inline-block;
        text-align: left;
        line-height: 1.875;
        @include media(tb) {
          font-size: $fontsize-base;
          line-height: 1.785;
        }
      }

      &_content {
        .item {
          display: flex;
          align-items: center;
          margin-top: 96px;
          @include media(tb) {
            display: block;
            margin-top: 80px;
          }
          &.-even {
            flex-direction: row-reverse;
          }
          .dl {
            .dt {
              font-size: 20px;
              color: $color-main;
              @include media(tb) {
                font-size: 16px;
              }    
            }
            .dd {
              font-size: 18px;
              line-height: 1.95;
              font-weight: normal;
              padding-top: 34px;
              text-align: justify;
              @include media(tb) {
                font-size: 16px;
                line-height: 2;
                padding-top: 32px;
              }    
            }
          }
        }
      }
      .sec_lv1 {
        margin: 96px auto 0;
        max-width: 850px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 36px 44px;
        border: $border-waku;
        border-radius: 20px;
        background-color: #eff7f5;
        @include media(tb) {
          display: block;
          padding: 24px;
        }
        &_content {
          text-align: center;
          padding-left: 26px;
        }
        .heading {
          font-size: 22px;
          font-weight: normal;
          display: inline-block;
          text-align: left;
          @include media(tb) {
            font-size: 18px;
          }
          &_wrap {
            text-align: center;
            @include media(tb) {
              margin-top: 32px;
            }
          }
        }
        .txt {
          font-size: 18px;
          padding-top: 8px;
          display: inline-block;
          text-align: left;
          @include media(tb) {
            font-size: 14px;
            line-height: 2.1;
          }
          &_wrap {
            text-align: center;
            @include media(tb) {
              padding-top: 32px;
            }
          }
        }
      }
      .sec_lv2 {
        margin-top: 96px;
        @include media(tb) {
          margin-top: 80px;
        }
        .flow {
          display: flex;
          flex-wrap: wrap;
          margin-left: -20px;
          @include media(tb) {
            display: block;
            margin-left: 0;
          }
          &_li {
            padding-left: 20px;
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include media(tb) {
              padding-left: 0;
              width: 100%;
              margin-top: 80px;
            }  
            .number {
              margin: 0 auto;
              color: $color-main;
              font-size: 28px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              border: $border-waku;
              border-radius: 9999px;
              width: 42px;
              height: 42px;
              @include media(tb) {
              }    
            }
            .txt_wrap {
              text-align: center;
            }
            .p {
              display: inline-block;
              text-align: left;
              padding-top: 4px;
              font-size: 18px;
              line-height: 1.95;
              @include media(tb) {
                font-size: 14px;
                line-height: 2.1;  
              }    
            }
          }
        }
      }
    
    }
  // ------------------------------------------section__lv5-end

  // ------------------------------------------section__lv6

  .section__lv6 {
    padding-bottom: 192px;
    &_title {
      font-size: 14px;
      margin-bottom: 17px;
      text-align: center;
      @include media(pc) {
        font-size: 18px;
        margin-bottom: 40px;
        text-align: left;  
      }
    }

    &_text {
      line-height: (25/14);
      text-align: left;
      @include media(pc) {
        font-size: 15px;
        line-height: (28/15);
      }
    }

    .border_kado_m {
      padding: 25px 13px;
      @include media(pc) {
        padding: 65px;
      }
    }
  }
  .workshop_container {
    @include media(pc) {
      margin-top: -10px;
    }
  }
  .workshop_title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @include media(pc) {
      margin-top: 50px;
      margin-bottom: 30px;
    }
    &__top {
      color: #fff;
      background-color: $color-main;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
      letter-spacing: (50em/1000);
      font-weight: 600;
      @include media(pc) {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
      }
    }
    &__bottom {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: #fff;
      color: $color-main;
      font-size: 16px;
      height: 34px;
      line-height: 34px;
      letter-spacing: (100em/1000);
      @include media(pc) {
        height: 50px;
        line-height: 50px;
        font-size: 24px;
      }
    }
  }
  .workshop_text {
    text-align: left;
  }
  .workshop_text2 {
    margin-top: 20px;
    @include media(pc) {
      margin-top: 25px;
    }
  }
  .workshop_lists {
    line-height: (25/14);
    margin-top: 15px - margin((25/14),14);
    @include media(pc) {
      font-size: 16px;
      line-height: (31/16);
    }
  }
  @media screen and (min-width: 960px) {
    .flex2 {
      display: flex;
      justify-content: space-between;
    }
    .flex2_text {
      flex-shrink: 0;
      width: 370px;
    }
    .workshop_lists {
      width: 348px;
      margin-top: 0;
    }
  }
  .course_lists {
    margin-top: 20px;
    line-height: (23.8/14);
    @include media(pc) {
      font-size: 16px;
      line-height: (25/16);
    }
  }
  .workshop_contact {
    margin-top: 25px;
    text-align: left;
    .inline {
      display: inline-block;
    }
    .text_indent {
      display: inline-block;
      margin-left: 41px;
    }
  }
  // ------------------------------------------section__lv6_end
  // ------------------------------------------section__lv7
  .section__lv7 {
    background-image: url(#{$IMG_PATH}business/business7-bg.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
    padding-top: 132px;
    padding-bottom: 66px;
    @include media(tb) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    &_title {
      font-size: 35px;
      text-align: center;
      @include media(tb) {
        font-size: 22px;
        line-height: (37/22);
      }
    }
    &_content {
      margin-top: 106px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -52px;
      @include media(tb) {
        display: block;
        margin-left: 0;
        margin-top: 40px;
      }
      .item {
        padding-left: 52px;
        width: 33%;
        @include media(tb) {
          padding-left: 0;
          width: 100%;
          padding-top: 48px;
        }
        &_inner {
          padding-top: 8px;
          padding-bottom: 24px;
          background-color: rgba(255,255,255,0.7);
          height: 100%;
          @include media(tb) {
            padding-top: 40px;
            padding-bottom: 40px;  
          }
        }
      }
    }
    &_heading {
      font-size: 20px;
      font-weight: normal;
      text-align: center;
      display: inline-block;
      text-align: left;
      padding-left: 4px;
      padding-right: 4px;
      @include media(tb) {
        font-size: 18px;
        padding-left: 0;
        padding-right: 0;  
      }

      &_wrap {
        text-align: center;
      }
    }
    &_txt {
      text-align: justify;
      padding-top: 26px;
      font-size: 18px;
      line-height: 1.95;
      padding-left: 24px;
      padding-right: 24px;
      @include media(tb) {
        font-size: 14px;
        line-height: (30/14);
      }
    }

    &_banner{
      padding-top: 66px;
      margin: 0 auto;

      &:hover{
        opacity: .8;
        transition: opacity .3s;
        cursor: pointer;
      }
    }


  }

  // ------------------------------------------section__lv7_end

  // ------------------------------------------section__lv8

  .section__lv8 {
    padding-top: 192px;
    @include media(tb) {
      padding-top: 80px;
    }
    &_content_text {
      text-align: center;
      max-width: 642px;
      margin: 14px auto 0;
    }
  
    &_lead__text {
      font-size: 16px;
      display: inline-block;
      text-align: left;
      line-height: 1.875;
      @include media(tb) {
        font-size: 14px;
        font-size: $fontsize-base;
        line-height: (30/14);
      }
    }

    .contact {
      margin-top: 38px;
      @include media(tb) {
        margin-top: 24px;
      }
    }
    .dl {
      font-size: 18px;
      display: flex;
      align-items: center;
      max-width: 480px;
      width: 100%;
      margin: 20px auto 0;
      @include media(tb) {
        display: block;
        text-align: center;
        max-width: 266px;
        margin: 32px auto 0;
      }
      .dt {
        white-space: nowrap;
        display: block;
        background-color: $color-main;
        padding: 16px 22px;
        color: white;
        @include media(tb) {
        }  
      }
      .dd {
        padding-left: 30px;
        @include media(tb) {
          padding-left: 0;
          padding-top: 12px;
        }  
      }
    }
    .section_shop {
      padding-top: 192px;
      @include media(tb) {
        padding-top: 80px;
      }
      .info {
        max-width: 600px;
        margin: 100px auto 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(tb) {
          display: block;
          margin: 32px auto 0;
        }  
        .logo {
          width: 147px;
          @include media(tb) {
            width: 112px;
            margin: 0 auto;
          }    
        }
        .address {
          @include media(tb) {
            display: inline-block;
            text-align: left;
            margin-top: 24px;
          }      
          &_wrap {
            @include media(tb) {
              text-align: center;
            }      
          }
          .name {
            font-size: 30px;
            @include media(tb) {
              font-size: 22px;
            }      
          }
          .address {
            font-size: 18px;
            line-height: 1.95;
            @include media(tb) {
              font-size: 14px;
              line-height: (30/14);
            }      
            .a {
              display: inline-block;
            }
          }
        }
        .sns {
          margin-top: 58px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media(tb) {
            margin-top: 24px;
          }    
          .fb {
            width: 57px;
            @include media(tb) {
              width: 38px;
            }      
          }
          .insta {
            margin-left: 40px;
            width: 48px;
            @include media(tb) {
              width: 32px;
              margin-left: 24px;
            }      
          }
        }
      }
    }
  
  }
  // ------------------------------------------section__lv8_end
}