@charset "UTF-8";

@import './functions';
// ----------------------------------
// サイトの基本設定
// ------------------------------------

//幅関係の設定
$width-base: 1000px; //全体の幅



//フォントの設定
$font-base: "ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
"ＭＳ Ｐゴシック",
sans-serif;
$font-serif: "ヒラギノ明朝 ProN W3",
"Hiragino Mincho ProN",
"HG明朝E",
"ＭＳ Ｐ明朝",
"ＭＳ 明朝",
serif;


//色関係の設定
//フォントカラー
$color-font: #231815;
//サイトカラー
$color-bg: #dff0eb;
$color-main: #c7161d;

//font-size
$fontsize-base: 14px;

//borderの設定
$border-red: 0.567px solid #c7161d;
$border-waku: 1.13px solid #c7161d;
$border-green: 0.567px solid #76a22d;
$border-gwaku: 1.13px solid #76a22d;

//行間のためのmargin_bottom
$line_height: 1em;

//formの角丸
$radius-form: 2.5px;


//IMG_PATH
$IMG_PATH: '../img/';

//ブレイクポイント
$breakpoints: (tb:"screen and (max-width:767px)",
pc:"screen and (min-width:768px)",
);

@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//疑似クラス用
@mixin gizi {
    content: '';
    display: block;
}

//文字の均等配置
@mixin kintou {
    -moz-text-align-last: justify;
    text-align-last: justify;
    text-justify: inter-ideograph;
}

//clearfix
@mixin clearfix {
    content: '';
    display: block;
    clear: both;
}

/*! =======================================
このCSSファイルはSassから生成されていますので、
編集しないようにご注意ください。
========================================= */

/*!
メインの設定(全体)
------------------------------- */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: $color-bg;
    font-size: $fontsize-base;
    width: 100%;
    font-family: $font-base;
    font-weight: normal;

    &::before {
        @include gizi;
        width: 100%;
        height: 77px;
    }

    @include media(pc) {
        &::before {
            display: none;
        }
    }
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $color-font;
    display: block;
}

a:hover {
    opacity: 0.8;
}

img {
    max-width: 100%;
    width: 100%;
    vertical-align: bottom;
}

p {
    line-height: 1.7;
}

h2 {
    font-size: 20px;

    @include media(pc) {
        font-size: 24px;
    }
}

.sp_none {
    display: none;

    @include media(pc) {
        display: block;
    }
}

@include media(pc) {
    .pc_none {
        display: none;
    }
}

.content_main,
footer {
    width: 93%;
}

.content_main,
header,
footer {
    max-width: $width-base;
    margin: 0 auto;

    @include media(pc) {
        width: 100%;
    }
}


.content_space {
    margin-bottom: 50px;

    @include media(pc) {
        margin-bottom: 80px;
    }
}

.content_news{
    font-weight: bold;
    margin-top: 10px;
    letter-spacing: 0.05em;
}

#form,
#company,
#business {
    margin-top: 50px;

    @include media(pc) {
        margin-top: 80px;
    }
}

.borderb_red {
    padding-bottom: 15px;
    margin-bottom: 22.5px;
    position: relative;

    &::after {
        @include gizi;
        width: 100%;
        bottom: 0;
        left: 0;
        border-bottom: $border-red;
        position: absolute;
        z-index: -1;
    }

    @include media(pc) {
        margin-bottom: 40px;
    }
}

.font_mincho {
    font-family: $font-serif;
    font-weight: normal;
}

.align_center {
    text-align: center;

    @include media(pc) {
        text-align: left;
    }
}

.box_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/*! header */
header {
    width: 100%;
    height: 77px;
    background-color: rgba($color: #fff, $alpha: 0.75);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;


    h1 {
        margin: 0;
        padding-top: 9px;
        padding-left: 11px;
        padding-bottom: 8px;
        z-index: 10;

        a {
            display: inline;
        }

        img {
            width: 187.5px;
        }
    }

    li {

        a {
            margin: 0 auto;
            border-bottom: 0.5px solid #231815;
            width: 93%;
            padding: 30px 0 12px;
        }

        @include media(pc) {
            margin-right: 1em;

            a {
                border-bottom: inherit;
                width: inherit;
                padding-bottom: 0;
                // padding-top: 25px;
                padding: 0;
            }
        }


        &:nth-last-of-type(1) {
            a {
                border-bottom: none;
                display: inline;
            }


        }

        .header_sns {
            width: 113px;
            @include kintou;
            margin: 0 auto;
            padding: 18px 0;

            img {
                width: 44px;
            }

            @include media(pc) {
                width: 90px;
                padding: 0;

                img {
                    width: 40px;
                }
            }
        }
    }

    @include media(pc) {
        background-color: inherit;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 10px;
        margin-bottom: 15px;
        position: static;

        h1 {
            padding: 0;

            img {
                width: 250px;
            }
        }
    }
}

.nav_sp {
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    text-align: center;
    transform: translateY(-100%);
    transition: all 0.6s;
    width: 100%;

    @include media(pc) {
        flex-shrink: 0;
        transform: inherit;
        background-color: inherit;
        position: static;
        width: auto;
        // text-align: right;
        // width: calc(100% - 250px);
        // max-width: 530px;

        ul {
            padding-left: 1em;
            justify-content: flex-end;
            align-items: flex-end;
            display: flex;
            width: 100%;

            li {
                margin-top: 50px;
                width: auto;

                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
            .nav_business {
                background-color: $color-main;
                color: #fff;
                padding: 4px;
                border-radius: 4px;

            }
            .nav_ec {
                background-color: white;
                color: $color-main;
                border-radius: 4px;
                border: 1px solid ;
                padding: 4px;
                display: flex;
                align-items: center;
                &_txt {
                    display: inline-block;
                    padding-left: 4px;
                    font-weight: bold;
                }
                .img_cart {
                    width: 26.65px;
                    height: 24.43px;
                }
            }
        }
    }
}

.nav_sp.active {
    transform: translateY(77px);
}

.nav_toggle {
    display: block;
    position: fixed;
    /* bodyに対しての絶対位置指定 */
    right: 11px;
    top: 0px;
    width: 66px;
    height: 70px;
    cursor: pointer;
    z-index: 3;
    text-align: center;

    @include media(pc) {
        display: none;
    }

    span {
        display: block;
        position: absolute;
        /* .navToggleに対して */
        width: 50px;
        border-bottom: solid 4px $color-main;
        -webkit-transition: .35s ease-in-out;
        -moz-transition: .35s ease-in-out;
        transition: .35s ease-in-out;
        left: 8px;

        &:nth-child(1) {
            top: 26px;
        }

        &:nth-child(2) {
            top: 42px;
        }

        &:nth-child(3) {
            top: 58px;
        }

    }
}

.nav_toggle.active {
    span {
        &:nth-child(1) {
            top: 42px;
            left: 6px;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &:nth-child(2) {
            top: 42px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            top: 42px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

/*! footer */

footer {
    position: relative;

    .content_sns {
        width: 120px;
        margin: 0 auto 30px;
        @include kintou;

        @include media(pc) {
            margin-bottom: 40px;
        }

        a {
            display: inline;
        }

        img {
            width: 50px;
        }
    }
}

.copy {
    text-align: center;
    font-size: 10px;
    margin-bottom: 20px;

    @include media(pc) {
        margin-bottom: 50px;
    }
}

.btn_top {
    font-size: $fontsize-base - 2px;
    position: absolute;
    right: 0;
    top: -20px;
    text-align: center;
    @include media(pc) {
    top: 14px;
    }

    span {
        position: relative;
        width: 29px;
        display: block;
        padding-top: 4px;

        &::after {
            @include gizi;
            position: absolute;
            width: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 14.5px 14px 14.5px;
            border-color: transparent transparent $color-main transparent;
            top: -14px;
            right: 0;

        }
    }

}

/*!
#topの独自設定
---------------------------- */
/* top内共通 */
.btn_white {
    margin-top: 15px;
    text-align: center;

    a {
        height: 40px;
        background-color: #fff;
        border: $border-red;
        font-size: 15px;
        line-height: 2.5;
    }

    @include media(pc) {
        a {
            height: 22px;
            font-size: 12px;
            line-height: 1.7;
        }
    }

}

h3 {
    font-size: 20px;
}


.content_course {
    position: relative;

    .img_first {
        width: 50px;
        position: absolute;
        top: 11.5px;
        right: 4%;
    }

    .content_text-tel{
      display: block;
    }
}

/* slider */

button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        @include media(pc) {
            margin: 0 15px;
        }

        button {
            border: 0;
            background: transparent;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: 1;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 8px;
                height: 8px;
                opacity: 0.25;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                background-color: #ed7e87;
                border-radius: 50%;
            }
        }

        &.slick-active button:before {
            opacity: 1;
            background-color: $color-main;
        }
    }
}

/* top内コンテンツ別 */
#top {
    .content_s1 {
        .img_contents1 {
            margin-bottom: 12px;
            text-align: right;
            width: 100%;
            background-image: url(#{$IMG_PATH}top_bg_01.png);
            background-size: 83px;
            background-repeat: no-repeat;
            background-position: right 109px bottom 10px;

            img {
                width: 119px;
            }
        }

        h3 {
            margin-bottom: 22px;
        }

        p {
            line-height: 2;
        }

        @include media(pc) {
            margin-top: 60px;
            display: flex;
            flex-direction: row-reverse;
            background-image: url(#{$IMG_PATH}top_bg_01.png);
            background-size: 166px 153px;
            background-repeat: no-repeat;
            background-position: right 250px center;

            .content_space {
                width: 526px;
                margin-left: 66px;
                margin-right: auto;
            }

            .img_contents1 {
                max-width: 238px;
                width: 27%;
                background-image: none;
                margin-right: 40px;

                img {
                    width: 100%;
                }

            }
        }
    }

    .content_s2 {
        h3 {
            position: relative;

            &::before {
                @include gizi;
                background-image: url(#{$IMG_PATH}top_bg_02.png);
                width: 75px;
                height: 74px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: -20px;
                left: 0;
                z-index: -1;
            }
        }

        .content_academy_2 {

            .img_content2 {
                margin-top: 12px;
                width: 30%;
                padding-bottom: 25px;

                &.img_yoko {
                    width: 36%;
                }
            }
        }

        .content_academy_1 {
            p+p {
                margin-top: 10px;
            }

            dl {
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
                line-height: 1.7;

                dt {
                    width: 75px;
                    position: relative;
                    padding-left: 15px;
                    margin-right: 15px;

                    &::before {
                        @include gizi;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $color-main;
                        position: absolute;
                        top: calc(50% - 5px);
                        left: 0;
                    }
                }

                dd {
                    width: calc(100% - 90px);
                }
            }

            .img_course {
                margin: 10px 0 0 4px;
            }

            .border_kado {
                border-top: $border-waku;
                width: calc(100% - 23px);
                margin: 0 auto;
                position: relative;

                &.border_green {
                    border-top: $border-gwaku;

                    &::before {
                        border-bottom: $border-gwaku;
                        border-right: $border-gwaku;
                        border-radius: inherit;
                    }

                    &::after {
                        border-bottom: $border-gwaku;
                        border-left: $border-gwaku;
                        border-radius: inherit;

                    }
                }

                &::before {
                    @include gizi;
                    width: 11.5px;
                    height: 11.5px;
                    border-bottom: $border-waku;
                    border-right: $border-waku;
                    border-bottom-right-radius: 100%;
                    position: absolute;
                    left: -11.5px;
                    top: 0;

                }

                &::after {
                    @include gizi;
                    width: 11.5px;
                    height: 11.5px;
                    border-bottom: $border-waku;
                    border-left: $border-waku;
                    border-bottom-left-radius: 100%;
                    position: absolute;
                    right: -11.5px;
                    top: 0;

                }
            }

            .border_kado_b {
                border-bottom: $border-waku;
                width: calc(100% - 23px);
                margin: 0 auto 10px;
                position: relative;

                &.border_green {
                    border-bottom: $border-gwaku;

                    &::before {
                        border-top: $border-gwaku;
                        border-right: $border-gwaku;
                        border-radius: inherit;
                    }

                    &::after {
                        border-top: $border-gwaku;
                        border-left: $border-gwaku;
                        border-radius: inherit;
                    }
                }

                &::before {
                    @include gizi;
                    width: 11.5px;
                    height: 11.5px;
                    border-top: $border-waku;
                    border-right: $border-waku;
                    border-top-right-radius: 100%;
                    position: absolute;
                    left: -11.5px;
                    top: -11.5px;

                }

                &::after {
                    @include gizi;
                    width: 11.5px;
                    height: 11.5px;
                    border-top: $border-waku;
                    border-left: $border-waku;
                    border-top-left-radius: 100%;
                    position: absolute;
                    right: -11.5px;
                    top: -11.5px;

                }
            }

            .content_course {
                padding: 10px 10px 10px 14px;
                position: relative;
                margin: 4px;

                &.border_square {
                    border: $border-red;
                }

                &.border_green {
                    border: $border-green;

                    &::after {
                        border-right: $border-gwaku;
                    }

                    &::before {
                        border-left: $border-gwaku;

                    }
                }

                &::after {
                    @include gizi;
                    height: calc(100% - 14px);
                    border-right: $border-waku;
                    position: absolute;
                    right: -5px;
                    top: 7.5px;
                }

                &::before {
                    @include gizi;
                    height: calc(100% - 14px);
                    border-left: $border-waku;
                    position: absolute;
                    left: -5px;
                    top: 7.5px;
                }

                .img_first {
                    width: 60px;
                }


                h4 {
                    font-size: 18px;
                    margin-bottom: 1em;

                    &.ma_b_0 {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include media(pc) {
            #content_academy {
                display: flex;

            }

            .content_academy_1 {
                min-width: 487px;

                p+p {
                    margin-bottom: 30px;
                    margin-top: 20px;
                }

                .content_course {
                    padding-left: 19px;
                }

                .img_course {
                    width: 152px;
                    margin: 0;
                }
            }

            .width_418 {
                width: 418px;
            }

            .width_315 {
                width: 315px;
            }

            .content_academy_2 {
                background-size: 200px;
                position: relative;
                width: 100%;

                &.box_flex {
                    flex-direction: column;
                }

                .img_content2 {
                    position: absolute;

                    &:nth-of-type(1) {
                        top: -75px;
                        left: -47px;
                        margin-top: 0;
                        max-width: 240px;
                        width: 50%;

                        img {
                            padding-right: 20px;
                        }
                    }

                    &:nth-of-type(2) {
                        top: 358px;
                        left: 0px;
                        margin-top: 0;
                        width: 55%;
                        max-width: 233px;
                        @media screen and (min-width: 768px){
                            left: -50px;
                        }
                        @media screen and (min-width: 970px){
                            left: 0px;
                        }
                    }

                    &:nth-of-type(3) {
                        top: 60px;
                        right: 7px;
                        margin-top: 0;
                        max-width: 275px;
                        width: 60%;


                    }
                    &:nth-of-type(4) {
                        top: 358px;
                        right: 0px;
                        margin-top: 0;
                        width: 25%;
                        @include media(pc) {
                            width: 55%;
                            max-width: 233px;
                        }
                    }
                }
            }

            .content_space {
                margin-bottom: 130px;
            }

            h3 {
                &::before {
                    width: 150px;
                    height: 148px;
                }
            }
        }
    }

    .content_s3 {
        .content_prof {
            &:nth-of-type(1) {
                min-height: 427px;
            }

            &:nth-of-type(2) {
                margin-top: 40px;
            }

            p {
                &.p_prof1 {
                    margin-bottom: 40px;
                }
            }

            .content_prof1_2 {
                @include clearfix;

                div {
                    margin-left: 10px;
                    text-align: right;
                    float: right;
                    width: 43%;
                    margin-top: -53.6px;
                    max-width: 187px;

                    .img_content2 {
                        &:nth-child(2) {
                            margin-top: 8px;
                            width: 81%;
                        }
                    }
                }
            }

            .content_prof2_2 {
                @include clearfix;

                div {
                    width: 33%;
                    margin-top: -53.6px;
                    margin-left: 10px;
                    float: right;
                }
            }
        }

        @include media(pc) {


            .content_prof {
                &:nth-of-type(1) {
                    min-height: inherit;
                }

                &:nth-of-type(2) {
                    margin-top: 50px;
                }

                h4 {
                    font-size: 18px;
                }

                p {
                    line-height: 2.1;

                    &.p_prof1 {
                        margin-bottom: 25px;
                    }
                }

                .content_prof1_2 {

                    div {
                        width: calc(100% - 455px);
                        display: flex;
                        align-items: baseline;
                        justify-content: space-around;
                        max-width: inherit;
                        margin: -110px auto 0;

                        .img_content2 {
                            &:nth-child(1) {
                                width: 48%;
                                max-width: 244px;
                            }



                            &:nth-child(2) {
                                margin-top: 0px;
                                width: 50%;
                                max-width: 214px;

                            }
                        }
                    }
                }

                .content_prof2_2 {
                    @include clearfix;
                    max-width: 818px;

                    div {
                        width: 196px;
                        margin: auto auto 0;
                    }
                }
            }
        }
    }

    .content_s4 {
        h3 {
            position: relative;

            &::before {
                @include gizi;
                background-image: url(#{$IMG_PATH}top_bg_04.png);
                width: 90px;
                height: 93px;
                background-size: contain;
                position: absolute;
                z-index: -1;
                right: 0;
                top: -3px;
            }
        }

        .content_amicafe {
            h4 {
                width: 27.5%;

            }

            .content_text {
                width: 100% - 29%;
                align-self: flex-start;
            }

        }

        #content_move {
            font-size: $fontsize-base - 2px;

            dl {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                max-width: 380px;

                dt {
                    @include kintou;
                    width: 50px;
                    margin-right: 25px;
                    margin-bottom: 15px;

                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                }

                dd {
                    margin-bottom: 15px;
                    width: calc(100% - 75px);

                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include media(pc) {
            h3 {
                &::before {
                    width: 180px;
                    height: 185px;
                    right: 25%;
                }
            }

            .content_amicafe {
                margin-left: 60px;

                .box_flex {
                    width: 611px;
                }

                h4 {
                    margin-right: 5%;
                    max-width: 115px;
                }

                .btn_white {
                    width: 100px;
                }
            }
        }
    }

    .content_s5 {
        &::before {
            @include gizi;
            border-top: $border-red;
            width: 100%;
            margin-bottom: 15px;
        }

        .content_map {
            img {
                margin-bottom: 5px;
            }
        }

        .content_cominfo {
            margin-top: 30px;

            h4 {
                font-size: 19px;
                margin-bottom: 15px;
            }
        }

        .content_ami1 {
            &::after {
                @include gizi;
                border-top: $border-red;
                width: 100%;
                margin-top: 20px;
                margin-bottom: 22px;
            }

            .content_text {
                p {
                    line-height: 2;
                }

                a {
                    display: inline;
                }
            }
        }

        .content_ami2 {
            h4 {
                width: 36%;
            }

            .content_text {
                width: 100% - 38%;
            }
        }

        @include media(pc) {
            .content_campany {
                display: flex;

                .btn_white {
                    width: 84px;
                }
            }

            &::before {
                margin-bottom: 40px;
            }

            .content_map {
                max-width: 375px;
                width: calc(100% - 481px);
                margin-right: 5%;
                text-align: center;

                p {
                    font-size: 12px;
                }

                .btn_white {
                    margin: 15px auto 0;
                }
            }

            .content_cominfo {
                margin-top: 0;
                width: 100%;
                max-width: 572px;
            }

            .content_ami2 {
                max-width: 428px;

                h4 {
                    max-width: 127px;
                }

                .content_text {
                    width: inherit;
                }
            }
        }
    }

}


/*!
#companyの独自設定
---------------------------- */
#company {
    dl {
        width: 100%;

        dt {
            width: 25%;
            @include kintou;
            margin-bottom: 25px;

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }

        dd {
            width: 70%;
            margin-bottom: 25px;

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }

            .line_height {
                display: block;
                margin-bottom: $line_height;
            }
        }
    }

    h3 {
        font-size: 16px;
        font-weight: normal;
    }

    @include media(pc) {
        &.content_main {
            display: flex;
            flex-direction: row-reverse;

            .content_s1 {
                width: 65%;
            }
        }

        dl {
            dt {
                width: 70px;
                margin-right: 20px;
            }

            dd {
                width: calc(100% - 90px);
            }
        }
    }
}

.content_logos {
    margin-top: 40px;

    .content_company_logo {
        width: 24%;
    }

    @include media(pc) {
        width: 35%;

        .content_company_logo {
            width: 100%;
            margin: 5% 35% 25%;

            &:nth-last-of-type(1) {
                margin: 0 35% 0;
            }
        }
    }
}

@include media(pc) {
    .margin_top40 {
        margin-top: 40px - 25px;
    }
}

/*!
#formの独自設定
---------------------------- */
#form {
    form {
        font-size: $fontsize-base - 2px;

        dl {
            dt {
                border: none;
                width: 26%;
                padding: 10px 0;
                margin-bottom: 5px;

                &.form_width100 {
                    margin-top: 14px;
                }
            }

            .form_width100 {
                width: 100%;
                padding: 0;
            }

            dd {
                padding: 0;
                width: 74%;
                border: none;

                input[type="email"] {
                    padding: 0.6em;
                    height: 35px;
                    border-radius: $radius-form;
                    border: none;
                    width: 100%;
                }

                textarea {
                    padding: 0.6em;
                    height: 105px;
                    border-radius: $radius-form;
                    border: none;
                    width: 100%;
                }

                input[type="text"] {
                    padding: 0.6em;
                    height: 35px;
                    border-radius: $radius-form;
                    border: none;
                    width: 100%;
                }
            }
        }

        .mfp_buttons {
            margin-top: 32.5px;



            .btn_green_c6e2ca {
                background-color: #c6e2ca;

                &:hover {
                    opacity: 0.8;
                }
            }

            .btn_green_76a22d {
                background-color: #76a22d;
                color: #fff;

                &:hover {
                    opacity: 0.8;
                }
            }

            .btn_type2 {
                min-width: 137px;
                max-width: 163px;
                width: 43%;
                max-height: 50px;
                line-height: 2.6;
                font-size: $fontsize-base + 4px;
                border-radius: 5px;
                border: none;
            }

            .formfont_14 {
                font-size: $fontsize-base;

            }

            .formfont_13 {
                font-size: $fontsize-base - 1px;

            }
        }

        .must {
            float: none;
            display: inline-block;
            background-image: none;
            text-shadow: none;
            box-shadow: none;
            border-radius: 7px;
            padding: 1px 3px;
        }
    }

    @include media(pc) {
        .align_center {
            text-align: center;
        }

        form {
            font-size: $fontsize-base;
            margin: 0 auto;
            max-width: 656px;

            dl {
                dt {
                    float: none;
                    width: 187px;
                    margin-right: 10px;
                    padding: 0;
                    padding-top: 5px;

                    &.form_width100 {
                        width: 187px;
                        margin-right: 10px;
                        margin-top: 0;
                        padding-top: 5px;
                    }

                    &.padding_right50 {
                        padding-right: 50px;
                        padding-top: 14px;
                    }
                }

                dd {
                    float: none;
                    width: calc(100% - 197px);
                    margin-bottom: 5px;

                    &.form_width100 {
                        width: calc(100% - 197px);
                    }

                    input[type="email"] {
                        border: 0.5px solid #231815;
                        height: 38px;
                        max-width: 318px;
                    }

                    textarea {
                        border: 0.5px solid #231815;
                        height: 114px;
                    }

                    input[type="text"] {
                        border: 0.5px solid #231815;
                        height: 38px;
                        width: 250px;
                    }
                }

                .formfont_13 {
                    padding-top: 8px;

                }
            }

            .must {
                border-radius: 15px;
                padding: 8px;
            }
        }
    }
}

//余白　2021/10/15 追加
.mb {
    &-1 {
        margin-bottom: 1rem;
    }
}

// バナーに対してのクラス

.banner--m{
    margin-top: 60px;
    margin-bottom: 100px;
}

@include media(pc){
    .banner-top-m{
        margin-top: 60px
    }
}


@import './business';